<template>
  <div class="detail positionr" v-loading="loading">
    <!-- 面包屑导航区 -->
    <el-row>
      <el-col
        ><el-breadcrumb
          ><el-breadcrumb-item class="pointer" :to="{ path: '/villageRoom' }"
            >导航</el-breadcrumb-item
          >
          <el-breadcrumb-item>详情</el-breadcrumb-item>
        </el-breadcrumb></el-col
      >
    </el-row>
    <!-- 内容主体 -->
    <div class="bgwhite bort">
      <el-row>
        <!-- tabbar -->
        <el-col :span="3">
          <div id="navigation">
            <ul>
              <li
                class="menu-item text-cut"
                v-for="(item, i) in tabs"
                :key="item"
                :class="seeThis == i ? 'active' : ''"
              >
                <a href="javascript:void(0)" @click="goAnchor(i)">{{ item }}</a>
              </li>
            </ul>
          </div>
        </el-col>
        <!-- tabbar对应内容 -->
        <el-col :span="21">
          <div class="menu-content">
            <!-- 基本信息 -->
            <div class="p1" id="a1">
              <div class="header-search">
                <div class="title">
                  <el-row>
                    <el-col :span="12">
                      <span class="line"></span>
                      基本信息
                    </el-col>
                    <el-col :span="12">
                      <div class="flex align-center justify-end h50">
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="保存基本信息和扩展属性"
                          placement="bottom"
                        >
                          <el-button
                            type="primary"
                            size="small"
                            @click="submitForm('villageRoom')"
                            >保存</el-button
                          >
                        </el-tooltip>
                        <el-tooltip
                          class="item"
                          effect="dark"
                          content="返回列表"
                          placement="bottom"
                        >
                          <el-button @click="goIndex()" size="small"
                            >返回</el-button
                          >
                        </el-tooltip>
                      </div>
                    </el-col>
                  </el-row>
                </div>
              </div>
              <div class="header-input">
                <el-form
                  :model="villageRoom"
                  ref="villageRoom"
                  label-width="150px"
                  class="villageRoom"
                  size="mini"
                >
                  <el-row>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="小区名称" prop="villageName">
                        <el-select
                          v-model="villageRoom.villageName"
                          @change="changeVillage"
                          placeholder="请选择小区"
                        >
                          <el-option
                            v-for="item in villageList"
                            :key="item.communityCode"
                            :label="item.villageName"
                            :value="JSON.stringify(item)"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>
                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="楼栋名称" prop="buildName">
                        <el-select
                          v-model="villageRoom.buildName"
                          @change="changeBuild"
                          placeholder="请选择楼栋"
                        >
                          <el-option
                            v-for="item in buildList"
                            :key="item.villageCode"
                            :label="item.buildName"
                            :value="JSON.stringify(item)"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="楼栋名称" prop="buildName">
                        <el-input v-model="villageRoom.buildName"></el-input>
                      </el-form-item>
                    </el-col> -->

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="单元名称" prop="unitName">
                        <el-select
                          v-model="villageRoom.unitName"
                          @change="changeUnit"
                          placeholder="请选择单元"
                        >
                          <el-option
                            v-for="item in unitList"
                            :key="item.buildCode"
                            :label="item.unitName"
                            :value="JSON.stringify(item)"
                          ></el-option>
                        </el-select>
                      </el-form-item>
                    </el-col>

                    <!-- <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="单元名称" prop="unitName">
                        <el-input v-model="villageRoom.unitName"></el-input>
                      </el-form-item>
                    </el-col> -->

                    <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="房间号" prop="roomName">
                        <el-input v-model="villageRoom.roomName"></el-input>
                      </el-form-item>
                    </el-col>

                 <!--   <el-col :sm="24" :md="24" :lg="12">
                      <el-form-item label="创建人" prop="creator">
                        <span>{{ villageRoom.creatorName }}</span>
                      </el-form-item>
                    </el-col> -->
                  </el-row>
                </el-form>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // 当前页数
      currPage: 1,
      pageSize: 10,
      seeThis: 0,
      loading: true,
      id: "",
      tabs: ["基本信息"],
      villageRoom: {
        id: "",
        communityCode: localStorage.getItem("communityCode"),
        communityName: localStorage.getItem("communityName"),
        villageCode: "",
        villageName: "",
        buildCode: "",
        buildName: "",
        unitCode: "",
        unitName: "",
        roomCode: "",
        roomName: "",
        createDt: "",
        creator: localStorage.getItem("userName"),
        creatorCode: localStorage.getItem("userCode"),
      },
      villageList: [],
      buildList: [],
      unitList: [],
    };
  },
  methods: {
    goIndex() {
      this.$router.push({
        path: "/villageRoom",
        query: { currPage: this.currPage, pageSize: this.pageSize },
      });
    },
    submitForm(formName) {
      var that = this;
      that.$http
        .post("/villageRoom/save", that.villageRoom)
        .then(function (response) {
          if (response.data.code == 200) {
            that.$notify.success({
              title: "提示",
              message: "保存成功",
              showClose: true,
            });
          } else {
            that.$notify.error({
              title: "提示",
              message: response.data.message,
              showClose: true,
            });
          }
        })
        .catch(function (error) {
          that.$notify.info({
            title: "提示",
            message: "保存失败",
            showClose: true,
          });
        });
    },
    loadBaseInfo() {
      let that = this;
      if (this.id != undefined && this.id != "") {
        that.$http
          .post("/villageRoom/queryOne", { id: this.id })
          .then(function (response) {
            if (response.data.code == 200) {
              that.total = response.data.data.count;
              that.villageRoom = response.data.data;
            }
          });
      }
    },
    goAnchor(index) {
      // 也可以用scrollIntoView方法， 但由于这里头部设置了固定定位，所以用了这种方法
      // document.querySelector('#anchor'+index).scrollIntoView()
      this.seeThis = index;
      var i = index + 1;
      var anchor = this.$el.querySelector("#a" + i);
      this.$nextTick(() => {
        this.$el.querySelector(".menu-content").scrollTop = anchor.offsetTop;
      });
    },
    handleScroll() {
      //console.log("滚动了");
      var scrollTop = this.$el.querySelector(".menu-content").scrollTop;
      var scrollHeight = this.$el.querySelector(".menu-content").scrollHeight;
      var height = this.$el.querySelector(".menu-content").offsetHeight;
      var anchorOffset1 = this.$el.querySelector("#a1").offsetTop;
      var anchorOffset2 = this.$el.querySelector("#a2").offsetTop;
      var anchorOffset3 = this.$el.querySelector("#a3").offsetTop;

      if (scrollTop > anchorOffset1 && scrollTop < anchorOffset2) {
        this.seeThis = 0;
      }
      if (scrollTop > anchorOffset2 && scrollTop < anchorOffset3) {
        this.seeThis = 1;
      }
      if (scrollTop > anchorOffset3 && scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }

      if (scrollTop + height > scrollHeight) {
        this.seeThis = 3;
      }
    },
      getVillageList() {
      let that = this;
      that.$http
        .post("/village/queryList", {
         communityCode: localStorage.getItem("communityCode"),
        })
        .then(function (response) {
          if (response.data.code == 200) {
            that.villageList = response.data.data;
            var villageCode = response.data.data[0].villageCode;
            that.getBuildList(villageCode);
          }
        });
    },

    getBuildList(villageCode) {
      let that = this;
      that.$http
        .post("/villageBuild/queryList", { villageCode: villageCode })

        .then(function (response) {
          if (response.data.code == 200) {
            that.buildList = response.data.data;
          }
        });
    },

    getUnitList(buildCode) {
      let that = this;
      that.$http
        .post("/villageUnit/queryList", { buildCode: buildCode })
        .then(function (response) {
          if (response.data.code == 200) {
            that.unitList = response.data.data;
          }
        });
    },
    changeVillage(e) {
      e = JSON.parse(e);
      this.villageRoom.villageName = e.villageName;
      this.villageRoom.villagedCode = e.villageCode;

      this.getBuildList(e.villageCode);
    },

    changeBuild(e) {
      e = JSON.parse(e);
      this.villageRoom.buildName = e.buildName;
      this.villageRoom.buildCode = e.buildCode;

      this.getUnitList(e.buildCode);
    },
    changeUnit(e) {
      e = JSON.parse(e);
      this.villageRoom.unitName = e.unitName;
      this.villageRoom.unitCode = e.unitCode;
    },
  },
  created() {
    if (this.$route.query.id != undefined && this.$route.query.id != "") {
      this.id = this.$route.query.id;
    }
    this.currPage = this.$route.query.currPage;
    this.pageSize = this.$route.query.pageSize;
    this.loadBaseInfo();
    this.getVillageList();
    this.BuildList();
  },
  mounted() {
    var that = this;
    this.$el.querySelector(".menu-content").onscroll = () => {
      that.handleScroll();
    }; // 监听滚动事件，然后用handleScroll这个方法进行相应的处理
    that.loading = false;
  },
};
</script>

<style lang="less" scope>
.h50 {
  height: 50px;
}
.detail {
  .el-breadcrumb__inner {
    color: #2f91df;
  }
  .el-row {
    #navigation {
      background-color: #f6f7fb;
      font-size: 14px;
      height: calc(100vh - 110px);
      margin-top: -14px;
      li {
        height: 50px;
        line-height: 50px;
        a {
          display: block;
          color: #324059;
          padding-left: 20px;
          background-color: transparent;
        }
      }
      li.active,
      li:hover {
        background-color: #fff;
      }
    }
    .menu-content {
      height: calc(100vh - 110px);
      overflow-y: auto;
      padding-left: 10px;
      padding-right: 5px;
      .p1 {
        margin-bottom: 20px;
      }
      .header-search {
        .title {
          height: 50px;
          line-height: 49px;
          font-size: 15px;
          color: #2f405b;
          padding-left: 10px;
          border-bottom: 1px solid #e3eaf0;
          margin-bottom: 10px;
          .line {
            display: inline-block;
            width: 3px;
            height: 15px;
            background-color: #2f91df;
            margin-right: 5px;
            border-radius: 2px;
            margin-bottom: -2px;
          }
          .el-input {
            width: 247px;
          }
          .el-input .el-input__inner {
            width: 217px;
          }
        }
      }
      .header-input {
        font-size: 13px;
      }
      .model-mask {
        .el-form {
          .el-form-item {
            margin-bottom: 0;
          }
        }
        .el-table {
          margin-top: 10px;
        }
      }
    }
  }
  #selectorOrgDialog .el-dialog {
    width: 600px !important;
  }
  #selectorOrgDialog .selectedUl {
    height: 350px;
    max-height: 350px;
  }
  #selectorOrgDialog .org-selected .selectedUl-box {
    height: 320px !important;
  }
}
</style>
